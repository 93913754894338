.landing-alert {
    margin-bottom: 0;
}

.service-box{
    flex: 0 0 20%;
    max-width: 20%;
}

.pic-1 {
    position  : relative;
    top       : 8%;
    text-align: left;
}

.logo {
    position: relative;
    left    : -5%;
}

.icon-1 {}

.icon-2 {
    position: relative;
    left    : 50px;
}

.icon-3 {
    position: relative;
    left    : 100px;
}

.icon-4 {
    position: relative;
    top     : 20px;
}

.heading {
    // color      : #000 !important;
    // text-shadow: 1px 1px 1px #fff;
    // font-size  : 35px;
    // font-weight: 500;

    color: #fff !important;
    font-size: 35px;
    font-weight: 800;
    text-transform: uppercase;
}

.product-page .heading {
    color: #000 !important;
    font-size: 35px;
    font-weight: 800;
    text-transform: uppercase;
}

.product-page .section-nucleo-icons {
    padding: 50px 0;
}

.footer-logo {
    position: relative;
    left    : -8%;
}

.text-icon {
    color      : #3A3A3C;
    font-weight: bold;
    font-size  : 20px;
    padding    : 0px 0 0 8px;
    position   : relative;
    top        : 3px;
}

.page-header {
    background-color      : #F3F3F3;
    background-position   : center center;
    background-size       : cover;
    // min-height         : 100vh;
    // max-height         : 999px;
    overflow              : hidden;
    position              : relative;
    width                 : 100%;
    z-index               : 1;
    display               : -ms-flexbox;
    display               : -webkit-flex;
    display               : flex;
    /* Center vertically */
    align-items           : center;
    /*Center horizontaly */
    justify-content       : left;

    /*Center horizontaly ie */
    -ms-flex-pack: center;


    .filter::after {
        background-color: rgba(0, 0, 0, 0.5);
        content         : "";
        display         : block;
        height          : 100%;
        left            : 0;
        top             : 0;
        position        : absolute;
        width           : 100%;
        z-index         : 1;
    }

    &.page-header-small {
        min-height: 65vh !important;
        max-height: 700px;
    }

    &.page-header-xs {
        min-height: 40vh !important;
    }

    &.section-dark {

        .content-center,
        .category-absolute {
            z-index: 1;
        }
    }
}

.page-header .motto {
    text-align: left;
    z-index   : 3;
    color     : #fff;
    position  : relative;

}

.landing-section {
    padding: 100px 0;
    z-index: 1;
}

.section-buttons {
    z-index: 1;
}

.landing-section .btn-simple {
    padding: 0;
}

.landing-section .column {
    padding: 0 75px 0 25px;
}

.team-player .img-circle,
.team-player .img-thumbnail {
    display     : block;
    margin-top  : 50px;
    margin-left : auto;
    margin-right: auto;
    width       : 120px;
}

.contact-form {
    margin-top: 30px;
}

.contact-form label {
    margin-top: 15px;
}

.contact-form .btn {
    margin-top: 30px;
}

.navbar-relative {
    position: relative;
    z-index : 2;
}

#register-navbar a {
    color: #FFF;
}

.register-background .container {
    margin-top: 11%;
    position  : relative;
    z-index   : 3;
}

.register-footer {
    bottom    : 20px;
    position  : absolute;
    z-index   : 1;
    width     : 100%;
    background: transparent;
    color     : $white-color;
    left      : 0;
}

.register-footer .fa-heart {
    color: #EB5E28;
}

.register-card label {
    margin-top: 15px;
}

.register-card .title {
    color     : #B33C12;
    text-align: center;
}

.register-card .btn {
    margin-top: 30px;
}

.register-card .forgot {
    text-align: center;
}

.profile-content {
    position: relative;
}

.owner {
    text-align: center;
}

.owner .avatar {
    padding  : 15px;
    max-width: 180px;
    margin   : -85px auto 0;
    display  : inline-block;
}

.owner .name h4 {
    margin-top: 10px;
}

.profile-tabs {
    margin    : 50px 0;
    min-height: 300px;
}

#following h3 {
    margin: 20px 0 40px 0;
}

#follows .follows .unfollow .checkbox {
    margin-top: -15px;
}

#follows .follows h6 {
    margin-top: 15px;
}

.follows hr {
    margin-top: 10px;
}

.alert h5 {
    // margin-bottom: 10px;
}

.title-brand {
    // max-width : 730px;
    // margin    : 0 auto;
    // position  : relative;
    // left      : 23%;
    // text-align: center;
    // color     : #FFFFFF;
    // display   : block;

    margin    : 0 auto;
    position  : relative;
    left      : 0%;
    top       : 0%;
    text-align: center;
    padding   : 0;
    color     : #FFFFFF;
    display   : block;
    height    : 126px;

    .type {
        position     : absolute;
        font-size    : 20px;
        background   : #132026;
        padding      : 6px 10px;
        border-radius: 4px;
        top          : 0;
        font-weight  : 600;
        margin-top   : 10px;
        right        : -15px;
    }
}

.presentation-title-pink-sm {
    font-size  : 4em;
    font-weight: 700;
    color      : #E72677;
}

.presentation-title-pink {
    font-size  : 65px;
    font-weight: 700;
    color      : #E72677;
}

.presentation-title-pink-dot {
    color: #E72677;
}

.presentation-title-green {
    color: #60aa46;
}

.presentation-title-black {
    // color: #60aa46;
    color:#E72677;
    font-size: 34px;
    text-align: left;
    font-weight: 800;
    line-height: 35px;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px #000;
}

.presentation-title-green {
    color: #60aa46;
    // color:#E72677;
    font-size: 22px;
    text-align: left;
    font-weight: 800;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px #000;
}

.guote-design{
    // position: absolute;
    // right: auto;
    // bottom: 20px;
    // // left: 32%;
    // left: -1%;
    // z-index: 10;
    // // top: 48%;
    // top: 57%;
    // color: #fff;
    // text-align: center;
    position: absolute;
    right: auto;
    bottom: 20px;
    left: -4%;
    z-index: 10;
    top: 52%;
    color: #fff;
    text-align: center;
}

.guote-design a{
    font-size: 18px;
}

.presentation-icon{
    color      : #fff;
    font-size  : 18px;
    text-align : left;
    font-weight: 400;
    margin: 20px 0 70px;
}

.presentation-icon img {
    margin: 0 0 22px;
    vertical-align: -webkit-baseline-middle;
}

.presentation-title {
    font-size    : 8em;
    font-weight  : 700;
    margin       : 0;
    color        : #FFFFFF;
    // background: #fbf8ec;
    background   : -moz-linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
    background   : -webkit-linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
    background   : -ms-linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
    background   : -o-linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
    background   : linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.presentation-subtitle {
    font-size: 1.7em;
    color    : #FFFFFF;
}

.category-absolute {
    position  : absolute;
    text-align: center;
    top       : 100vh;
    margin-top: -60px;
    padding   : 0 15px;
    width     : 100%;
    color     : rgba(255, 255, 255, 0.5);

    .creative-tim-logo {
        max-width: 140px;
        top      : -2px;
        left     : 3px;
        position : relative;
    }
}

.fog-low {
    position     : absolute;
    left         : 0;
    bottom       : 0;
    margin-left  : -35%;
    margin-bottom: -50px;
    width        : 110%;
    opacity      : .85;

    img {
        width: 100%;
    }

    &.right {
        margin-left: 30%;
        opacity    : 1;
    }
}

.angular-logo {
    max-width: 90px;
    margin   : -50px auto 45px;

    img {
        width: 100%;
    }
}

.page-header {
    .content-center {
        //margin-top: 85px;
    }
}

.moving-clouds {
    position         : absolute;
    z-index          : 1;
    bottom           : 0;
    left             : 0;
    width            : 250.625em;
    height           : 43.75em;
    -webkit-animation: cloudLoop 80s linear infinite;
    animation        : cloudLoop 80s linear infinite;
}

@keyframes cloudLoop {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0)
    }

    100% {
        -webkit-transform: translate3d(-50%, 0, 0);
        transform        : translate3d(-50%, 0, 0)
    }
}

.following {
    img {
        max-width: 70px;
    }
}

.ie-background {
    .page-header {
        .content-center {
            top          : 50%;
            left         : 50%;
            position     : absolute;
            -ms-transform: translate(-50%, -75%);

            .title-brand {
                .presentation-title {
                    background: none;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .presentation-title {
        font-size: 5em;
    }
}