/*          Changes for small display      */

@media (max-width: 767px) {

    .explore-more {
        margin       : 0;
        position     : absolute;
        left         : 50%;
        -ms-transform: translate(-50%, -50%);
        transform    : translate(-50%, -50%);
        margin       : 15px 0 0 0;

        a {
            font-size: 16px;
            width    : 250px;
        }
    }

    .col-sm-2,
    .col-sm-3 {
        width  : auto;
        padding: 0;
    }

    .box-white {
        max-width: 290px;
    }

    .navbar-transparent {
        // padding-top: 15px;
        background-color: rgba(0, 0, 0, 0.45);
    }

    body {
        //  position: relative;
        position : absolute;
        font-size: $font-size-small;
    }

    h6 {
        font-size: 1em;
    }

    p {
        font-size  : 25px;
        padding    : 0 40px;
        line-height: 2.4em;
        text-align : justify;
    }

    .navbar-brand {
        width : 250px;
        margin: 40px auto 0px !important;
    }

    .subscribe-box {
        width: 54% !important;
        top  : 0 !important;
    }

    .subscribe-box p {
        font-size  : 18px;
        line-height: 2.4em;
    }

    .sub-img img {
        width   : 120px !important;
        position: relative;
        top     : 15% !important;
        left    : 5% !important;
    }

    .sub-img {
        width: 41%;
    }

    .footer-logo {
        width  : 100%;
        padding: 0 0 30px;
    }

    .navbar .container {
        left : 0;
        width: 100%;
        @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
        position: relative;
    }

    .navbar .navbar-nav .dropdown-menu {
        height    : 400px;
        overflow-y: scroll;
    }

    .navbar .navbar-collapse.collapse,
    .navbar .navbar-collapse.collapse.in,
    .navbar .navbar-collapse.collapsing {
        // display: none !important;
    }

    .demo-header .motto {
        padding-top: 30% !important;
    }

    .navbar-toggle .icon-bar {
        display      : block;
        position     : relative;
        background   : #fff;
        width        : 24px;
        height       : 2px;
        border-radius: 1px;
        margin       : 0 auto;
    }

    .title-brand {
        left: 5% !important;
    }

    .fog-low {
        margin-bottom: -35px;
    }

    .presentation-title {
        font-size: 5em;
    }

    .presentation-subtitle {
        margin-top: 40px;
    }

    .title-brand {
        max-width: 450px;

        .type {
            font-size: 16px;
        }
    }

    .navbar-header .navbar-toggle {
        margin-top: 12px;
        width     : 40px;
        height    : 40px;
    }

    .bar1,
    .bar2,
    .bar3 {
        outline: 1px solid transparent;
    }

    .bar1 {
        top: 0px;
        @include bar-animation($topbar-back);
    }

    .bar2 {
        opacity: 1;
    }

    .bar3 {
        bottom: 0px;
        @include bar-animation($bottombar-back);
    }

    .toggled .bar1 {
        top: 6px;
        @include bar-animation($topbar-x);
    }

    .toggled .bar2 {
        opacity: 0;
    }

    .toggled .bar3 {
        bottom: 6px;
        @include bar-animation($bottombar-x);
    }

    @include topbar-x-rotation();
    @include topbar-back-rotation();
    @include bottombar-x-rotation();
    @include bottombar-back-rotation();

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @-moz-keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .navbar-nav {
        margin: 1px -15px;

        .open .dropdown-menu>li {
            &>a {
                padding: 15px 15px 5px 50px;
            }

            &:first-child>a {
                padding: 5px 15px 5px 50px;
            }

            &:last-child>a {
                padding: 15px 15px 25px 50px;
            }
        }
    }

    [class*="navbar-"] .navbar-nav {

        &>li>a,
        >li>a:hover,
        >li>a:focus,
        .active>a,
        .active>a:hover,
        .active>a:focus,
        .open .dropdown-menu>li>a,
        .open .dropdown-menu>li>a:hover,
        .open .dropdown-menu>li>a:focus,
        .navbar-nav .open .dropdown-menu>li>a:active {
            color: white;

        }

        &>li>a:not(.btn),
        >li>a:hover,
        >li>a:focus,
        .open .dropdown-menu>li>a:not(.btn),
        .open .dropdown-menu>li>a:hover,
        .open .dropdown-menu>li>a:focus {
            opacity   : .7;
            background: transparent;
        }

        &.navbar-nav .open .dropdown-menu>li>a:active {
            opacity: 1;
        }

        & .dropdown>a {
            &:hover .caret {
                border-bottom-color: #777;
                border-top-color   : #777;
            }

            &:active .caret {
                border-bottom-color: white;
                border-top-color   : white;
            }
        }

    }

    .bootstrap-datetimepicker-widget {
        visibility: visible;
    }

    .dropdown-menu {
        display: none;
    }

    .navbar-fixed-top {
        -webkit-backface-visibility: hidden;
    }

    .social-line .btn {
        margin: $margin-bottom;
    }

    .subscribe-line .form-control {
        margin: $margin-bottom;
    }

    .social-line.pull-right {
        float: none;
    }

    .footer nav.pull-left {
        float: none !important;
    }

    .footer:not(.footer-big) nav>ul li {
        float: none;
    }

    .footer {

        .footer-nav,
        .credits {
            margin: 0 auto;
        }
    }

    .section-buttons {
        .input-group {
            margin-top: 15px;
        }

        .form-group.has-danger {
            .form-control-feedback {
                margin-top: 5px;
            }
        }
    }

    .social-area.pull-right {
        float: none !important;
    }

    .form-control+.form-control-feedback {
        margin-top: -8px;
    }

    .navbar-toggle:hover,
    .navbar-toggle:focus {
        background-color: transparent !important;
    }

    .btn.dropdown-toggle {
        margin-bottom: 0;
    }

    .media-post .author {
        width  : 20%;
        float  : none !important;
        display: block;
        margin : 0 auto 10px;
    }

    .media-post .media-body {
        width: 100%;
    }

    .modal-footer .btn-simple {
        padding: 15px;
    }

    .table-responsive {
        width                     : 100%;
        margin-bottom             : 15px;
        overflow-x                : scroll;
        overflow-y                : hidden;
        border                    : 1px solid #dddddd;
        -ms-overflow-style        : -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }

    .typography-line {
        padding-left: 100px !important;
    }

    .projects-1 {
        & .project-pills {
            & .nav.nav-pills {
                display: block !important;

                .nav-item {
                    &:first-child .nav-link {
                        border-radius: 12px 12px 0 0 !important;
                        margin-left  : -1px;
                    }

                    &.active:first-child .nav-link {
                        border-radius: 12px 12px 0 0 !important;
                        margin-left  : -1px;
                    }

                    &:last-child .nav-link {
                        border-radius: 0 0 12px 12px !important;
                        margin-left  : -1px;
                    }
                }
            }
        }
    }

    .testimonials-2,
    .section-testimonials {
        .testimonials-people {
            img {
                display: none !important;
            }
        }
    }

    .presentation-page {

        .section-cards,
        .section-components {
            overflow: hidden;

            .first-card {
                top: 750px !important;

                .grid__link {
                    width: 200px !important;
                }
            }

            .fourth-card {
                top: 940px !important;

                .grid__link {
                    width: 200px !important;
                }
            }

            .fifth-card {
                top : 950px !important;
                left: 220px !important;

                .grid__link {
                    width: 200px !important;
                }
            }

            .sixth-card {
                top : 1335px !important;
                left: 220px !important;

                .grid__link {
                    width: 200px !important;
                }
            }

            .seventh-card {
                top: 1155px !important;

                .grid__link {
                    width: 200px !important;
                }
            }
        }

        .section-content {
            .image-container {
                .add-animation {
                    height: 250px !important;
                    width : 180px !important;
                }
            }
        }

        .section-components {
            .image-container {
                .components-macbook {
                    width : 580px !important;
                    height: 400px !important;
                }

                .social-img,
                .share-btn-img {
                    display: none;
                }
            }

            .title {
                margin-top: -100px !important;
            }
        }

        .section-examples {
            padding-top: 0 !important;
            margin-top : 10px !important;
        }

        .section-icons {
            .icons-nucleo {
                .nc-icon:not(.ninth-left-icon):not(.seventh-left-icon):not(.third-left-icon) {
                    display: none !important;
                }
            }
        }
    }

    #typography {
        .typography-line {
            .note {
                margin-left: 7px !important;
            }
        }
    }

    .section-nucleo-icons {
        [class*='col-lg'] {
            text-align   : center !important;
            margin-bottom: 2em;
        }
    }
}

.form-control {
    border: 1px solid #ccc;
    width : 40% !important;
    margin: 0 0 20px;
}

.about-img {
    padding: 105px 0 0 0;
}

.what-we-do {
    padding: 30px 0 0 100px;
}

.what-we-are {
    padding: 115px 0 0 0;
}

.div-box{
    box-shadow: 0px 0px 1px #000;
    border-radius: 10px;
    position: relative;
    padding: 20px;
    background: #eee;
    left: 5%;
}

@media screen and (max-width: 991px) {


    .form-left-box{
        float: left;
        width:100%;
        vertical-align: top;

        .form-label{
            float: left;
            width:35%;
            margin:0;
            padding: 0;
        }

        .form-input{
            float: left;
            width:65%;
            margin:0;
        }

        .product-heading h6{
            font-size: 13px !important;
            text-align: left !important;
        }

        
    }

    .form-right-box{
        float: left;
        width:100%;
        vertical-align: top;

        .form-checkbox{
            float: left;
            width:10%;
        }

        .form-check-label{
            float: left;
            width:30%;
            text-align: left;
        }
    }

    .form-bottom-left-box{
        float: left;
        width:107%;
        vertical-align: top;

        .form-tbl tr{
            float: left;
            width:106%;
        }

        .form-checkbox{
            float: left;
            width:5%;
        }

        .form-check-label{
            float: left;
            width:42%;
            text-align: left;
        }
    }

    .form-bottom-right-box{
        float: left;
        width:100%;
        vertical-align: top;

        .form-checkbox{
            float: left;
            width:5%;
        }

        .form-check-label{
            float: left;
            width:45%;
            text-align: left;
        }
    }
    

    .footer-address {
        margin : 20px 0;
        padding: 0 30px;
    }

    .explore-more .btn-pink {
        background-color: #5EAA44; 
        border-color: #5EAA44;
    }

    .div-box {
        // box-shadow: 0px 0px 1px #000;
        border-radius: 10px;
        border: 1px solid #000;
        position: relative;
        padding: 20px;
        background: #eee;
        width: 100%;
        left: 5%;
    }

    .for-mobile-css {
        margin      : 0;
        padding-left: 0px !important;

        ul {

            li {
                border-bottom: 1px solid #000;
                padding-left : 30px !important;


            }
        }

        

        .btn-pink {
            width: 185px !important;
            left : 8% !important;

            a {
                color: #fff !important;
            }
        }

        .btn-success {
            width: 185px !important;
            left : 8% !important;
            top  : 2% !important;

            a {
                color: #fff !important;
            }
        }

        .btn-call {
            width           : 185px !important;
            background-color: #000;
            border-color    : #fff;
            color           : #FFFFFF;
            border-radius   : 30px;
            left            : 8% !important;

            a {
                color: #fff !important;
            }

            i {
                color      : #fff !important;
                font-size  : 16px;
                font-weight: bold;
                position   : relative;
                top        : 3px;
            }

            span{
                padding-left: 4px !important;
            }
        }
    }

    .payment-banner h5,
    .contact-banner h5,
    .about-banner h5,
    .product-banner h5 {
        font-size: 50px !important;
    }

    .text-center {
        line-height: 30px !important;
    }

    .heading {
        font-size: 15px !important;
    }

    .product-white-box .title {
        font-size: 12px !important;
    }

    .subscribe-box p {
        font-size: 12px !important;
    }

    .full-width {
        width: 100% !important;
    }

    .form-control {
        width: 100% !important;
    }

    .contact-box {

        img {
            width: 40% !important;
        }

        .title {
            margin-top   : 10px !important;
            margin-bottom: 0 !important;
            font-size    : 8px !important;
        }

    }

    .section-nucleo-icons {
        // padding: 25px 0;
        padding: 25px 0 100px;
    }

    .about-bg {
        margin-top: 5px !important;
    }

    .text-left-full-width {
        width     : 100% !important;
        text-align: left;
    }

    .contact-banner,
    .career-banner,
    .about-banner,
    .product-banner {
        min-height: 200px !important;
    }

    .contact-banner h5 {
        position : absolute;
        top      : 11%;
        left     : 50%;
        transform: translate(-50%, -50%);
        padding  : 0 !important;
    }

    .product-banner h5 {
        position : absolute;
        top      : 5%;
        left     : 50%;
        transform: translate(-50%, -50%);
        padding  : 0 !important;
    }

    .about-banner h5 {
        position : absolute;
        top      : 5%;
        left     : 50%;
        transform: translate(-50%, -50%);
        padding  : 0 !important;
    }

    .career-banner h5 {
        position : absolute;
        top      : 8%;
        left     : 50%;
        transform: translate(-50%, -50%);
        padding  : 0 !important;
    }

    .career-banner-1 h5 {
        position : absolute;
        top      : 6%;
        left     : 50%;
        transform: translate(-50%, -50%);
        padding  : 0 !important;
    }

    .job-desc p {
        font-size  : 1.5em;
        padding    : 0 10px;
        line-height: 2.4em;
        text-align : center;
        color      : #000;
        font-size  : 14px !important;
    }

    .job-desc h4 {
        margin: 30px 0 !important;
    }

    .job-desc ul {
        text-align: left;
        font-size : 14px;
    }


    .what-we-do {
        padding: 0;
    }

    .navbar-brand {
        width : 250px;
        margin: 20px 0 20px 74px !important;
    }

    .contact-page .contact-info {
        padding   : 0 0 0 5% !important;
        text-align: left !important;
    }

    // ======= Banner Start ======= 

    .banner-spam-img,
    .banner-form-img,
    .banner-4,
    .banner-5,
    .banner-6,
    .banner-img {
        height: 150px !important;
    }

    .payment-banner {
        min-height: 150px !important;

        h5 {
            padding  : 50px 0 0 0 !important;
            font-size: 35px !important;
        }
    }

    .banner-text {
        height: 40%;
    }

    .carousel-caption {
        left: -28% !important;
        top : 42% !important;
    }

    .guote-design a {
        font-size: 9px !important;
    }

    .carousel-inner {
        height: 160px;
    }

    .page-header {
        height : 150px;
        display: block !important;
    }

    .banner-text {
        position: absolute;
        left    : 10%;
        top     : 8% !important;
    }

    .bottom-box {
        flex: 0 0 20% !important;
    }

    // ======= Banner End ======= 

    .about-width {
        width: 40%;
    }

    .about-bg {
        background-position: unset !important;
    }

    .left-box {
        flex     : 0 0 40%;
        max-width: 40%;

        img {
            position: relative;
            top     : 23%;
            left    : -10%;
        }
    }

    .right-box {
        flex     : 0 0 60%;
        max-width: 60%;
    }

    .right-box {
        flex     : 0 0 60%;
        max-width: 60%;
    }

    .text-justify {
        font-size: 14px;
    }

    .text-center {
        font-size: 14px !important;
    }

    .about-img {
        img {
            width: 100px;
        }
    }

    .what-we-do {
        img {
            width: 200px;
        }
    }

    .what-we-are {
        img {
            width: 200px;
        }
    }

    .about-content {
        width     : 100% !important;
        text-align: left;
        font-size : 11px !important;
        color     : #000;
    }

    .service-box {
        flex     : 0 0 100% !important;
        max-width: 100% !important;
        padding  : 0 32px;
    }

    // .service-box-2 {
    //     padding:0 0 0 18%!important
    // }

    .sub-img {
        width: 100%;
    }

    .subscribe-box {
        width      : 100% !important;
        margin-left: 10%;
    }

    .about-img {
        padding: 0;
    }

    .product-white-box {
        min-height: 310px !important;
        max-height: 500px !important;
        margin    : 2% 0 0px;

        .text-center {
            font-size: 1em;
        }
    }

    .text-center {
        margin: 0 0 30px;
    }

    .review-bg .carousel-inner {
        height: 570px;
    }

    .feedback-box {
        height: 440px;
    }

    .subscribe {
        width : 100% !important;
        height: auto !important;
    }

    .subscribe p {
        padding: 0;
    }

    .subscribe h4 {
        text-align: left;
    }

    .text-pink {
        width: 52% !important;
    }

    .subscribe-bg {
        height: auto !important;
    }

    .home-page-row {
        margin-right: 20px !important;
        margin-left : 20px !important;
    }

    .row {
        margin-right: 0px !important;
        margin-left : 0px !important;
    }

    .navbar-nav ul {
        display: none !important;
    }

    .explore-more a {
        font-size: 20px;
        position : relative;
        top      : 25px;
        width    : 250px;
    }

    .product-page .heading {
        font-size: 21px !important;
    }

    .product-page .headings {
        font-size     : 22px !important;
        color         : #000 !important;
        font-weight   : 800;
        text-transform: uppercase;
    }



    // .for-web{
    //     display: none !important;
    // }

    // .section-nucleo-icons {
    //     padding: 0;
    // }

    .navbar-collapse {
        display         : block;
        position        : fixed;
        top             : 0;
        height          : 100%;
        width           : 230px;
        right           : 0;
        z-index         : 1032;
        visibility      : visible;
        background-color: #999;
        overflow-y      : visible;
        border-top      : none;
        text-align      : left;
        border-left     : 1px solid #CCC5B9;
        padding-right   : 0px;
        padding-left    : 40px;
        padding-top     : 15px;

        @include transform-translate-x(230px);
        @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

        ul {
            position     : relative;
            z-index      : 3;
            // overflow-y:scroll;
            height       : 95%;
        }

        .navbar-nav>.nav-item {

            // border-bottom: 1px solid;
            &:last-child {
                border-bottom: 0;
            }

            &>.nav-link {
                margin        : 0px 0px;
                color         : $dark-gray !important;
                text-transform: uppercase;
                font-weight   : 600;
                font-size     : $font-size-small;
                line-height   : $line-height-general;
                padding       : 15px 0;

                &:hover,
                &:active {
                    color: $default-states-color !important;
                }
            }
        }

        &::after {
            top             : 0;
            left            : 0;
            height          : 100%;
            width           : 100%;
            position        : absolute;
            background-color: $bg-nude;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
            display         : block;
            content         : "";
            z-index         : 1;
        }

        &.has-image::after {
            @include black-filter(.8);
        }
    }

    .nav-open {
        & .navbar-collapse {
            @include transform-translate-x(0px);
            display: block;
        }

        & .wrapper {
            left: 0;
            @include transform-translate-x(-230px);
        }

        & .navbar-translate {
            @include transform-translate-x(-230px);
        }

    }

    .wrapper .navbar-collapse {
        display: none;
    }

    .fixed-top .navbar-collapse {
        background-color: #FF8F5E;
        // width: 100%;
        float           : left;
    }

    .dropdown.show .dropdown-menu,
    .dropdown .dropdown-menu {
        background-color  : transparent;
        border            : 0;
        transition        : none;
        -webkit-box-shadow: none;
        box-shadow        : none;
        width             : auto;
        border-radius     : 0;


        .dropdown-item:hover,
        .dropdown-item:focus {
            background-color: transparent;
            border-radius   : 0;
        }

        .dropdown-item a {

            &:hover,
            &:focus {
                color: $default-states-color;
            }
        }

        &:before,
        &:after {
            display: none;
        }
    }

    .dropdown {
        .dropdown-menu {
            display: none;
        }

        &.show .dropdown-menu {
            display: block;
        }
    }

    .navbar-translate {
        justify-content: space-between;
        display        : flex;
        align-items    : center;
        width          : 100%;
        position       : relative;
        @include transform-translate-x(0);
        @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    }

    .wrapper {
        //    @include transform-translate-x(0px);
        @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
        left            : 0;
        background-color: white;
    }
}



@media screen and (min-width:767px) {
    .for-mobile {
        display: none !important;
    }

    

    // ======= Banner Start ======= 

    .banner-spam-img,
    .banner-form-img,
    .banner-4,
    .banner-5,
    .banner-6,
    .banner-img {
        height: 300px !important;
    }

    .carousel-inner {
        height: 310px;
    }

    .page-header {
        height : 300px;
        display: block !important;
    }

    .guote-design a {
        font-size: 14px !important;
    }

    .banner-text {
        height: 40%;
    }

    .carousel-caption {
        left: -2% !important;
        top : 55% !important;
    }

    .banner-text {
        position: absolute;
        left    : 10%;
        top     : 8% !important;
    }

    .bottom-box {
        flex: 0 0 20% !important;
    }

    // ======= Banner End ======= 

    .product-banner h5 {
        top: 17%;
    }

    .subscribe-box {
        left: 11%;
    }

    .about-banner h5 {
        top: 17%;
    }

    .text-justify {
        padding: 0 0 0 30px;
    }

    .what-we-are img {
        width: 250px;
    }

    .career-banner h5 {
        top: 17%;
    }

    .contact-banner h5 {
        top: 17%;
    }

    .job-post {
        min-height: 500px;
        max-height: 300px;

        p {
            text-align: justify;
        }
    }

    .about-content {
        width     : 100% !important;
        text-align: left;
        font-size : 16px !important;
    }

    .job-post-tablet {
        min-height: auto !important;
        max-height: 100% !important;
    }

    .form-inline {
        display: contents !important;
    }

    .home-page-row {
        padding: 0 35px;
    }

    .for-mobile {
        display: block;
    }

    .service-box {
        flex     : 0 0 50% !important;
        max-width: 50% !important;
        padding  : 0 10px;
        margin   : 0 0 16px;
    }

    .product-white-box {
        min-height: 390px !important;
        max-height: 390px !important;
        margin    : 2% 0 0px;
    }

    .explore-more {
        margin   : 0;
        position : absolute;
        left     : 50%;
        transform: translate(-50%, -50%);

        a {
            font-size: 20px;
            position : relative;
            top      : 65px;
            width    : 350px;
        }
    }

    .section-nucleo-icons {
        padding: 25px 0 150px;
    }

    .subscribe-box {
        flex     : 0 0 75%;
        max-width: 55%;
    }

    .sub-img {
        flex     : 0 0 25%;
        max-width: 25%;
    }

    .heading {
        font-size: 23px !important;
    }

    .section-sections {
        .section-cols {
            position: relative;
            z-index : 1;

            .row {
                &:first-of-type {
                    margin-top  : 50px;
                    margin-right: -120px;
                    margin-left : -15px;
                    @include transform-translate-x(-80px);
                }

                &:nth-of-type(2) {
                    margin-left : -100px;
                    margin-right: -15px;
                    transform   : translateX(80px);
                }

                &:nth-of-type(3) {
                    margin-right: -120px;
                    margin-left : 0px;
                    transform   : translateX(-120px);
                }

                &:nth-of-type(4) {
                    margin-right: -100px;
                    margin-left : -15px;
                    transform   : translateX(-50px);
                }
            }

        }
    }
}

@media screen and (min-width:991px) {

    // Desktop CSS

    .full-width{
        width:100%
    }
    .form-left-box{
        float: left;
        width:50%;
        vertical-align: top;

        table{
            float: left;
            width: 100%;
        }

        .form-label{
            float: left;
            width:35%;
            margin:0;
            padding: 0;
        }

        .form-input{
            float: left;
            width:65%;
            margin:0;
        }

        .product-heading h6{
            font-size: 13px !important;
            text-align: left !important;
        }

        
    }

    .form-right-box{
        float: left;
        width:50%;
        vertical-align: top;

        table{
            float: left;
            width: 100%;
        }

        .form-checkbox{
            float: left;
            width:10%;
        }

        .form-check-label{
            float: left;
            width:30%;
            text-align: left;
        }
    }

    .form-bottom-left-box{
        float: left;
        width:50%;
        vertical-align: top;

        table{
            float: left;
            width: 100%;
        }

        .form-checkbox{
            float: left;
            width:5%;
        }

        .form-check-label{
            float: left;
            width:42%;
            text-align: left;
        }
    }

    .form-bottom-right-box{
        float: left;
        width:50%;
        vertical-align: top;

        .sperator-line{
            border-left: 1px solid #000;
            vertical-align: top;
            padding: 0 25px;
        }

        table{
            float: left;
            width: 100%;
        }

        .form-checkbox{
            float: left;
            width:5%;
        }

        .form-check-label{
            float: left;
            width:45%;
            text-align: left;
        }
    }

    // ======= Banner Start ======= 

    .banner-spam-img,
    .banner-form-img,
    .banner-4,
    .banner-5,
    .banner-6,
    .banner-img {
        height: 450px !important;
    }

    .carousel-inner {
        height: 460px;
    }

    .page-header {
        height : 450px;
        display: block !important;
    }

    .guote-design a {
        font-size: 14px !important;
    }

    .banner-text {
        // height: 40%;
        height: 54%;
    }

    .carousel-caption {
        left: -2% !important;
        top : 68% !important;
    }

    .banner-text {
        position: absolute;
        left    : 10%;
        top     : 8% !important;
    }

    .bottom-box {
        flex: 0 0 20% !important;
    }

    // ======= Banner End ======= 

    .explore-more {
        margin       : 0;
        position     : absolute;
        left         : 50%;
        -ms-transform: translate(-50%, -50%);
        transform    : translate(-50%, -50%);

        a {
            font-size: 20px;
            position : relative;
            top      : 30px;
            width    : 350px;
            margin: 0 0 50px;
        }
    }

    .subscribe {
        width  : 65% !important;
        padding: 0 37px !important;
    }

    .sub-img {
        flex     : 0 0 40% !important;
        max-width: 40% !important;
    }

    .subscribe-box {
        flex     : 0 0 50% !important;
        max-width: 50% !important;
    }

    .for-mobile {
        display: block;
    }

    .service-box {
        flex     : 0 0 20% !important;
        max-width: 20% !important;
    }

    .product-page .service-box {
        flex     : 0 0 20% !important;
        max-width: 20% !important;
    }

    .product-page {
        padding: 50px 0 !important;
    }

    .product-page-div .service-box {
        flex     : 0 0 25% !important;
        max-width: 25% !important;
    }

    .product-page .product-white-box {
        min-height: 480px !important;
    }

    .product-page .product-white-small-box {
        min-height: 325px !important;
    }

    .guote-margin{
        margin:15px 0 0 0;
    }

    .product-desc{
        min-height: 292px;
        max-height: 292px;
    }

    .product-small-desc{
        min-height: 125px;
        max-height: 125px;
    }

    .product-xsmall-desc{
        min-height: 190px;
        max-height: 190px;
    }

    .home-page-row {
        // padding: 0 30px;
        padding: 50px 30px;
    }

    .job-post {
        min-height: 330px;
        max-height: 300px;
    }

    .form-inline {
        display: flex !important;
    }

    .burger-menu {
        .navbar-collapse {
            position        : fixed;
            display         : block;
            top             : 0;
            height          : 100%;
            width           : 230px;
            right           : 0px;
            z-index         : 1032;
            visibility      : visible;
            background-color: #999;
            overflow-y      : visible;
            border-top      : none;
            text-align      : left;
            border-left     : 1px solid #CCC5B9;
            padding-right   : 0px;
            padding-left    : 40px;
            padding-top     : 15px;
            @include transform-translate-x(230px);
            @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

            .navbar-nav .nav-item {
                &:last-child {
                    border-bottom: 0;
                }
            }

            .navbar-nav {
                height                : 100%;
                z-index               : 2;
                position              : relative;
                display               : -webkit-box;
                display               : -webkit-flex;
                display               : -ms-flexbox;
                display               : flex;
                -webkit-box-orient    : vertical;
                -webkit-box-direction : normal;
                -webkit-flex-direction: column;
                -ms-flex-direction    : column;
                flex-direction        : column;
            }

            &::after {
                top             : 0;
                left            : 0;
                height          : 100%;
                width           : 100%;
                position        : absolute;
                background-color: $bg-nude;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
                display         : block;
                content         : "";
                z-index         : 1;
            }

            &.has-image::after {
                @include black-filter(.8);
            }
        }

        .navbar {
            .container {
                .navbar-toggler {
                    display   : block;
                    margin-top: 20px;
                }
            }
        }

        .navbar-translate {
            width   : 100%;
            position: relative;
            @include transform-translate-x(0);
            @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
        }

        .wrapper {
            //    @include transform-translate-x(0px);
            @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
            left            : 0;
            background-color: white;
        }

        .body {
            .navbar-collapse.collapse {
                height: 100vh !important;
            }
        }

        .navbar-collapse,
        .navbar-nav {

            &:before,
            &:after {
                display: table;
                content: " ";
            }
        }

        .nav-open & {
            .navbar-collapse.collapse {
                @include transform-translate-x(0px);
            }

            .wrapper {
                left: 0;
                @include transform-translate-x(-230px);
            }

            .navbar-translate {
                @include transform-translate-x(-230px);
            }
        }

        .dropdown.show .dropdown-menu,
        .dropdown .dropdown-menu {
            background-color  : transparent;
            border            : 0;
            transition        : none;
            -webkit-box-shadow: none;
            box-shadow        : none;
            width             : auto;
            border-radius     : 0;


            .dropdown-item:hover,
            .dropdown-item:focus {
                background-color: transparent;
                border-radius   : 0;
            }

            .dropdown-item a {

                &:hover,
                &:focus {
                    color: $default-states-color;
                }
            }

            &:before,
            &:after {
                display: none;
            }
        }

        .dropdown {
            .dropdown-menu {
                display: none;
            }

            &.show .dropdown-menu {
                display: block;
            }
        }
    }
}

@media screen and (max-width: 1025px) {
    .input-group {
        .input-group-addon {
            padding-right: 10px;
        }
    }
}